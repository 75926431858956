






























































































import { defineComponent, computed, ref, Ref } from '@vue/composition-api';
import { useCampaigns } from '@/composition/campaign';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcCheckFilter from '@/components/FcCheckFilter.vue';

export default defineComponent({
  name: 'Campaigns',
  components: {
    FcRoleLoading,
    FcCheckFilter,
  },
  setup(_, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('subscriptionCampaigns'));

    const { notify, error } = useNotification();
    const { confirmDialog } = useConfirm();

    const isFetchLoading = ref(true);
    const isSaving = ref(false);
    const route = context.root.$route;

    const { campaigns } = useCampaigns();
    campaigns
      .init()
      .catch((e) => error(e))
      .finally(() => (isFetchLoading.value = false));

    const headers = [
      { text: 'ステータス', value: 'status' },
      { text: 'キャンペーン名', value: 'subscriptionCampaignName' },
      { text: 'キャンペーン期間', value: 'campaignPeriodDate' },
      { text: 'トライアル期間', value: 'trialPeriod' },
      { text: '対象プラン', value: 'subscriptionPlanNames' },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];

    // アーカイブ表示の可否
    const isDisplayArchive = ref(false);
    // プランで絞り込み
    const selectedPlanValues: Ref<string[]> = ref(
      typeof route.query.planId === 'string' ? route.query.planId.split(',') : []
    );
    // ステータスで絞り込み
    const selectedStatusValues: Ref<string[]> = ref([]);
    const statusOptions = [
      { text: '公開', value: 'release' },
      { text: '開始前', value: 'reserve' },
      { text: '終了', value: 'end' },
      { text: '下書き', value: 'draft' },
      { text: 'アーカイブ', value: 'archive' },
    ];
    const displayCampaigns = computed(() =>
      campaigns.items
        // 退会ユーザー含めるか
        .filter((item) => isDisplayArchive.value || !item.isArchive)
        // 指定プランによる絞り込み
        .filter(
          (item) =>
            !selectedPlanValues.value.length ||
            item.subscriptionPlanIds?.some((planId) => selectedPlanValues.value.includes(planId)) ||
            selectedPlanValues.value.length === campaigns.plans.length
        )
        // 指定ステータスによる絞り込み
        .filter(
          (item) => !selectedStatusValues.value.length || selectedStatusValues.value.includes(item.displayStatus.value)
        )
    );

    const archive = async (id: string) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      isSaving.value = true;
      try {
        await campaigns.delete(id);
        notify('削除しました。');
      } catch (e) {
        error(e);
      } finally {
        isSaving.value = false;
      }
    };

    return {
      pageTitle: 'キャンペーン一覧',
      myRoleSettings,
      campaigns,
      displayCampaigns,
      headers,
      archive,
      isFetchLoading,
      isSaving,
      isDisplayArchive,
      selectedPlanValues,
      selectedStatusValues,
      statusOptions,
    };
  },
});
