var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[(!_vm.myRoleSettings)?_c('fc-role-loading',{attrs:{"my-role-settings":_vm.myRoleSettings,"page-title":_vm.pageTitle}}):[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-spacer'),_c('fc-check-filter',{staticClass:"mr-1",attrs:{"options":_vm.statusOptions,"label":"ステータスで絞り込み"},model:{value:(_vm.selectedStatusValues),callback:function ($$v) {_vm.selectedStatusValues=$$v},expression:"selectedStatusValues"}}),_c('fc-check-filter',{staticClass:"mr-1",attrs:{"options":_vm.campaigns.plans,"label":"プランで絞り込み"},model:{value:(_vm.selectedPlanValues),callback:function ($$v) {_vm.selectedPlanValues=$$v},expression:"selectedPlanValues"}}),(_vm.myRoleSettings.createAndUpdate)?_c('v-btn',{staticClass:"ml-1",attrs:{"elevation":"0","color":"primary","to":"/campaign/create"}},[_vm._v("新しいキャンペーンを登録")]):_vm._e()],1),_c('v-switch',{staticClass:"pa-0 ma-0 px-3",attrs:{"label":"アーカイブしたキャンペーンも表示する"},model:{value:(_vm.isDisplayArchive),callback:function ($$v) {_vm.isDisplayArchive=$$v},expression:"isDisplayArchive"}}),_c('v-sheet',{attrs:{"outlined":"","rounded":""}},[_c('v-data-table',{staticClass:"fixed-header",attrs:{"headers":_vm.headers,"items":_vm.displayCampaigns,"items-per-page":-1,"loading":_vm.isFetchLoading,"loader-height":"2","hide-default-footer":"","height":_vm.tableHeight},scopedSlots:_vm._u([{key:"item.subscriptionCampaignName",fn:function(ref){
var item = ref.item;
return [(!item.isArchive)?_c('router-link',{attrs:{"to":("/campaign/" + (item.subscriptionCampaignId))}},[_vm._v(_vm._s(item.subscriptionCampaignName))]):[_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.subscriptionCampaignName))])]]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:{
              info: item.displayStatus.value === 'end' || item.displayStatus.value === 'reserve',
              primary: item.displayStatus.value === 'draft',
              success: item.displayStatus.value === 'release',
            },attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.displayStatus.text))])])]}},{key:"item.subscriptionPlanNames",fn:function(ref){
            var item = ref.item;
return [(item.subscriptionPlanNames.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.subscriptionPlanNames.length)+"プラン")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(item.subscriptionPlanNames.join('<br>'))}})]):_c('span',[_vm._v("-")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.myRoleSettings.delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","loading":_vm.isSaving,"disabled":item.isArchive},on:{"click":function($event){return _vm.archive(item.subscriptionCampaignId)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("アーカイブ")])]):_vm._e()]}}],null,true)})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }